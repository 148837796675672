import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useField } from 'formik';

import '../../styles/inputStyles.css';
import './CheckboxLabelLink.css';
import PromptWithActionLink from './PromptWithActionLink';

const CheckboxLabelLink = forwardRef(({ name, label, field, form, setRef, removeFocusFromAll, nextFieldFocus, placeholder, validate, onLinkPress }, ref) => {
    console.log("Render CheckboxLabelLink");

    const [isFocused, setFocused] = useState(false);
    useImperativeHandle(setRef(name), () => ({
        removeFocus: () => {
            setFocused(false);
        },
        isFocused: () => {
            return isFocused;
        },
        SetFocus: () => {
            setFocused(true);
/*            dateInputRef.current.focus();*/
        },
        myUniqueId: name
    }), [isFocused, name]);

    const handleBlur = () => {
        setFocused(false);
        nextFieldFocus(name);
    };


    const toggleCheckbox = async () => {
        const newValue = !field.value;
        await form.setFieldValue(field.name, newValue);

        // Устанавливаем touched только если значение false
        if (!newValue) {
            form.setFieldTouched(field.name, true, false);
        }

        if (!isFocused) {
            if (removeFocusFromAll) {
                removeFocusFromAll(name);
            }
            setFocused(true);
        }
    };

    return (

        <div className="checkboxLabelLink-container">
            <div
                onClick={toggleCheckbox}
                name={name}
                validate={validate}
                onBlur={() => setFocused(false)}
                onFocus={() => {
                    removeFocusFromAll(name);
                    setFocused(true);
                }}
            >
                <div className="checkboxLabelLink-wrapper">
                    <div className={`border checkboxLabelLink-checkbox ${isFocused ? 'focused' : ''}`}>
                        {field.value ? (
                            <span className="checkboxLabelLink-icon">✔️</span>
                        ) : null}
                    </div>
                    <div className="parent-centered-div">
                        <PromptWithActionLink
                            prompt={label}
                            buttonText={placeholder}
                            onLinkPress={onLinkPress}
                        />
                    </div>
                </div>
            </div>
            {form.touched[field.name] && form.errors[field.name] && (
                <div className="errorText">{form.errors[field.name]}</div>
            )}
        </div>
    );
});

export default CheckboxLabelLink; 