import axios from "axios";
import google_client_config from "../static/json/google_client_config.json";
import {
  IS_TEST_MODE,
  BASE_URL,
  API_GOOGLE_AUTH_ENDPOINT,
} from "../config/config";

const googleService = async () => {
  if (IS_TEST_MODE) {
    console.log("googleService IS_TEST_MODE", IS_TEST_MODE);
    return {
      status: 200,
      data: { token: "mock_access_token" },
    };
  }

  try {
    const config = {
      response_type: "code",
      client_id: google_client_config.web.client_id,
      scope: "profile email",
      redirect_uri: `${BASE_URL}${API_GOOGLE_AUTH_ENDPOINT}`,
      access_type: "offline", // Для получения refresh token
      prompt: "consent", // Всегда показывать окно согласия
      state: crypto.randomUUID(), // Защита от CSRF атак
    };
    console.log("googleService config", config);

    const params = new URLSearchParams(config).toString();
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
    console.log("googleService url", googleAuthUrl);

    window.location.href = googleAuthUrl;
  } catch (error) {
    console.log("googleService error", error);
    return {
      status: 500,
      data: { error: error.message },
    };
  }
};

export default googleService;

//// Функция для получения фиктивных данных в тестовом режиме
//const getMockGoogleData = () => {
//    return {
//        status: 200,
//        data: {
//            token: 'mock_access_token'
//        }
//    };
//};

//// Отправляет запрос авторизации на сервер
//const sendGoogleAuthRequest = async () => {
//    const url = `${BASE_URL}${API_GOOGLE_AUTH_ENDPOINT}`;
//    axios.defaults.withCredentials = true;
//    return axios.get(url, { validateStatus: status => status < 500 });
//};

//// Генерирует объект ошибки и возвращает структурированный ответ об ошибке
//const handleErrorResponse = (errorMessage, errorStatus) => {
//    console.warn('Google Auth error:', errorMessage);
//    return { status: errorStatus, data: { error: errorMessage } };
//};

//// Основная функция авторизации через Google
//export const googleServiceVeryOld = async () => {
//    console.log('googleService IS_TEST_MODE', IS_TEST_MODE);
//    if (IS_TEST_MODE) {
//        return getMockGoogleData();
//    }

//    try {
//        await sendGoogleAuthRequest();
//        // Данные обрабатываются на бэкенде и происходит редирект, здесь не нужно ничего возвращать
//    } catch (error) {
//        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
//    }
//};

//const googleServiceOld = async () => {
//    console.log('googleService IS_TEST_MODE', IS_TEST_MODE);
//    if (IS_TEST_MODE) {
//        return new Promise(resolve => setTimeout(() => {
//            resolve({
//                status: 200,
//                data: { token: 'mock_access_token' }
//            });
//        }, 500));
//    }

//    try {
//        const config = {
//            response_type: 'token',
//            client_id: google_client_config.web.client_id,
//            scope: 'profile email',
//            redirect_uri: window.location.origin,
//        };
//        console.log('googleService config', config);

//        const params = new URLSearchParams(config).toString();
//        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
//        console.log('googleService url', googleAuthUrl);

//        window.location.href = googleAuthUrl;

//        return new Promise((resolve, reject) => {
//            window.addEventListener('message', (event) => {
//                if (event.origin === window.location.origin) {
//                    resolve({
//                        status: 200,
//                        data: { token: event.data.token }
//                    });
//                } else {
//                    reject(new Error('Invalid origin'));
//                }
//            });
//        });

//    } catch (error) {
//        console.log('googleService error', error);
//        return {
//            status: 500,
//            data: { error: error.message }
//        };
//    }
//};
