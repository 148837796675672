// validationSchema.js
import * as Yup from 'yup';
import appConfig from '../../static/json/appConfig.json';

export const makeValidationSchema = (userLanguage) => {
    const commonText = appConfig[userLanguage]["common"];

    return Yup.object({
        name: Yup.string()
            .min(5, commonText['User name must contain at least 5 characters'])
            .required(commonText['Required field']),
        email: Yup.string()
            .email(commonText['Invalid email'])
            .required(commonText['Required field']),
        password: Yup.string()
            .min(8, commonText['Password must contain at least 8 characters'])
            .required(commonText['Required field']),
        cofirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], commonText['Passwords must match'])
            .required(commonText['Required field']),
        agreeToTerms: Yup.boolean()
            .required(commonText['Required field'])
            .test('is-accepted', commonText['You must accept the terms of using'],
                function (value) {
                    // Добавляем проверку на undefined
                    return value === true;
                }
            ),
    });
};




