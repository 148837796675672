import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';

import '../../styles/inputStyles.css';

import AstrologAIText from '../AstrologAIText/AstrologAIText';
import { useTokenAuthentication } from '../../hooks/useTokenAuthentication';

import SignIn from '../signin/SignIn';
import SignUp from '../signup/SignUp';
import Prediction from '../prediction/Prediction';
import Verification from '../verification/Verification';
import Subscription from '../subscription/Subscription';
import Confirmation from '../subscription/Confirmation';
import OrderCompletedSuccess from '../subscription/OrderCompletedSuccess';
import OrderCompletedFailure from '../subscription/OrderCompletedFailure';
import Profile from '../profile/Profile';
import Terms from '../terms/Terms';
import GreetingForm from '../GreetingForm/GreetingForm';
import LanguageSelector from '../language/LanguageSelector';
import LanguageForm from '../language/LanguageForm';
import Footer from '../footer/Footer';
import ContactPage from '../footer/ContactPage';

import './AppRouter.css';

const AppRouter = () => {
    const { checkToken } = useTokenAuthentication();
    const [isAuth, setIsAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const authenticate = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const authData = urlParams.get('auth_data');

            if (urlParams.get('message')) {
                navigate('/order-success');
                return;
            }

            if (urlParams.get('error')) {
                navigate('/order-failure');
                return;
            }

            if (authData) {
                const decodedData = JSON.parse(decodeURIComponent(authData));
                if (decodedData.token) {
                    localStorage.setItem('userToken', decodedData.token);
                }
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            const response = await checkToken();

            if (!response.success) {
                navigate('/');
                return;
            }

            if (
                !response.user.is_registration_completed ||
                response.user.birth_country === ''
            ) {
                navigate('/profile');
                return;
            }

            if (!response.user.subscription_type) {
                navigate('/subscription');
                return;
            }

            navigate('/prediction');
        };

        authenticate();
    }, []);

    return (
        <>
            <div className="header">
                <img src="/logo.webp" alt="Logo" className="main-logo" />
                <AstrologAIText />
                <LanguageSelector />
            </div>

            <div className="main-content">
                <Routes>
                    <Route path="/" element={<GreetingForm />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/verification" element={<Verification />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/language-form" element={<LanguageForm />} />
                    <Route
                        path="/subscription"
                        element={<Subscription header="true" />}
                    />
                    <Route
                        path="/confirmation"
                        element={
                            <Confirmation
                                amount={5}
                                plan={'STANDARD'}
                                period={'monthly'}
                            />
                        }
                    />
                    <Route path="/prediction/*" element={<Prediction />}>
                        <Route index element={<Navigate to="personal" replace />} />
                    </Route>
                    <Route path="/order-success" element={<OrderCompletedSuccess />} />
                    <Route path="/order-failure" element={<OrderCompletedFailure />} />
                    <Route path="/terms" element={<Terms showForm={false} />} />
                    <Route path="/terms-form" element={<Terms showForm={true} />} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes>
            </div>
            {/*            <div className="footer">*/}
                <Footer />
{/*            </div>*/}
        </>
    );
};
export default AppRouter;
