import React from 'react';
import { useField } from 'formik';

import './CheckboxLabel.css';
import '../../styles/inputStyles.css';

const CheckboxLabel = ({ name, label }) => {
    const [field, , helpers] = useField(name);

    const toggleCheckbox = () => {
        const newValue = !field.value;
        helpers.setValue(newValue);
        helpers.setTouched(true);
    };

    return (
        <div onClick={toggleCheckbox} className="container checkboxlabel-container">
            <div className="border checkboxlabel-checkbox">
                {field.value ? (
                    <span className="checkboxlabel-icon">✔️</span>
                ) : null}
            </div>
            <span className="text left20">{label}</span>
        </div>
    );
};

export default CheckboxLabel;